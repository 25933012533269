<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="80%">
      <div class="flex" >
        <el-select v-model="type" placeholder="请选择发送对象" v-if="isShow">
          <el-option label="默认通知" :value="0"></el-option>
          <el-option label="营业通知" :value="1"></el-option>
        </el-select>
        <div style="line-height: 40px; margin-left: 20px">
          当前总数：{{ list.filter((item) => item.type == type).length }}条
        </div>
      </div>
      <el-table
        :data="list.filter((item) => item.type == type)"
        style="width: 100%; margin-top: 20px"
        stripe
        border
        max-height="700"
      >
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column
          prop="tenant_id"
          label="发送商户编号"
        ></el-table-column>
        <el-table-column label="商户名称">
          <template #default="scope">
            <div class="a" @click="clickName(scope.row)">
              {{ scope.row.tenant_store_name }}
            </div>
          </template></el-table-column
        >
        <el-table-column prop="mold" label="发送模式">
          <template #default="scope">
            <div>{{ scope.row.mold == "wechat" ? "微信" : "短信" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="发送对象">
          <template #default="scope">
            <div>
              {{ scope.row.type == "member" ? "全部会员" : "全部员工" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          width="400"
        ></el-table-column>
        <el-table-column prop="schedule" label="进度描述"></el-table-column>
        <el-table-column
          prop="send_time"
          label="发送时间"
          width="170"
        ></el-table-column>
        <el-table-column
          prop="business_mold"
          label="营业状态"
        ></el-table-column>
        <el-table-column label="记录分类">
          <template #default="scope">
            <div>{{ scope.row.type ? "营业通知" : "默认通知" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建日期"
          width="170"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
      type: 0,
    };
  },
  mounted() {
  },
};
</script>