  <!-- 群发申请 -->
  <template>
  <div>
    <div class="flex">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        @click="renew"
        :loading="renewStatus"
      ></el-button>
      <el-button type="primary" @click="addList">新增群发申请</el-button>
    </div>

    <el-table
      :data="list.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
      style="width: 100%; margin-top: 20px"
      stripe
      border
      max-height="700"
    >
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="tenant_id" label="发送商户编号"></el-table-column>
      <el-table-column label="商户名称">
        <template #default="scope">
          <div class="a" @click="clickName(scope.row)">
            {{ scope.row.tenant_store_name }}
          </div>
        </template></el-table-column
      >
      <el-table-column prop="mold" label="发送模式">
        <template #default="scope">
          <div>{{ scope.row.mold == "wechat" ? "微信" : "短信" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="发送对象">
        <template #default="scope">
          <div>{{ scope.row.type == "member" ? "全部会员" : "全部员工" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        width="400"
      ></el-table-column>
      <el-table-column prop="schedule" label="进度描述"></el-table-column>
      <el-table-column
        prop="send_time"
        label="发送时间"
        width="170"
      ></el-table-column>
      <el-table-column prop="business_mold" label="营业状态"></el-table-column>
      <el-table-column label="记录分类">
        <template #default="scope">
          <div>{{ scope.row.type ? "营业通知" : "默认通知" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建日期"
        width="170"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="220">
        <template #default="scope">
          <div class="flex">
            <el-button @click="edit(scope.row)" icon="el-icon-edit"></el-button>
            <el-button
              @click="del(scope.row)"
              icon="el-icon-delete"
            ></el-button>
            <el-button
              @click="open(scope.row)"
              icon="el-icon-s-promotion"
            ></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 15, 20]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="list.length"
      style="margin: 20px 0"
    >
    </el-pagination>
    <el-dialog :visible.sync="dialogVisible" @close="dialogHide">
      <el-form label-width="100px">
        <el-form-item label="发送商户编号">
          <el-input v-model="form.tenant_id"></el-input>
        </el-form-item>
        <el-form-item label="营业状态">
          <el-input v-model="form.business_mold"></el-input>
        </el-form-item>
        <el-form-item label="商户名称">
          <el-input v-model="form.tenant_store_name"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.memo"></el-input>
        </el-form-item>
        <el-form-item label="发送对象">
          <el-select v-model="form.mobiles" placeholder="请选择发送对象">
            <el-option label="全部会员" value="member"></el-option>
            <el-option label="全部员工" value="staff"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送模式">
          <el-select v-model="form.mold" placeholder="请选择发送模式">
            <el-option label="短信" value="sms"></el-option>
            <el-option label="微信" value="wechat"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送状态">
          <el-select v-model="form.schedule" placeholder="请选择发送状态">
            <el-option label="已发送" value="已发送"></el-option>
            <el-option label="处理中" value="处理中"></el-option>
          </el-select>
        </el-form-item>
        <!--  <el-form-item label="创建日期">
          <el-date-picker
            v-model="form.create_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发送时间">
          <el-date-picker
            v-model="form.send_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="回执结果">
          <el-input v-model="form.result"></el-input>
        </el-form-item>
        <el-form-item label="通知类型">
          <el-select v-model="form.type" placeholder="请选择通知类型">
            <el-option label="默认通知" :value="0"></el-option>
            <el-option label="营业通知" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <div class="center">
          <el-button type="primary" round style="width: 200px" @click="save"
            >提交</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <shopList ref="shopList" :isShow="true"></shopList>
  </div>
</template>
  
  <script>
import axios from "axios";
import { getList, setList, addList, delList } from "@/apis/api.js";
import shopList from "@/views/components/applys/components/shopList.vue";
export default {
  components: {
    shopList,
  },
  data() {
    return {
      renewStatus: false, //刷新按钮状态
      list: [],
      dialogVisible: false,
      form: {},
      currentPage: 1,
      pageSize: 10,
      filter: [],
    };
  },
  methods: {
    clickName(obj) {
      this.$refs.shopList.list = this.list.filter(
        (item) => item.tenant_store_name == obj.tenant_store_name
      );
      this.$refs.shopList.dialogVisible = true;
    },
    open(obj) {
      console.log(obj);
      let year = obj.create_time.substring(0, 4);
      /*   let month = obj.create_time.substring(5, 7);

      let maxDay = new Date(year, month, 0).getDate(); */
      let data = {
        type: obj.type,
        tenant_id: obj.tenant_id,
        start_create_time: `${year}-01-01 00:00:00`,
        end_create_time: `${year}-12-31 23:23:59`,
      };
      let maxNum = obj.type ? 4 : 2;
      this.$confirm(`确定要发送通知嘛?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getList(data).then((res) => {
          let arr = res.data.data
            ? res.data.data.filter(
                (item) => item.send_time != null && item.mold == "wechat"
              )
            : [];
          console.log(res.data.data, arr);
          if (arr.length < maxNum || obj.mold != "wechat") {
            this.send(obj);
            return;
          }
          this.$confirm(
            `${year}年发送次数超过${maxNum}次,已发送${arr.length}次,确定还要发送嘛?`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            this.send(obj);
          });
        });
      });
    },
    send(obj) {
      axios({
        url: `https://business.haswallow.com/api/peiyu/tenant/send?id=${obj.id}`,
        method: "GET",
      }).then((res) => {
        if (!res.data.code) {
          console.log(res, "发送成功");
          this.$message({
            type: "success",
            message: "通知成功!",
          });
          obj.schedule = "已发送";
          setList(obj).then((res) => {
            if (!res.data.code) {
              this.getList();
            }
          });
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    del(obj) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delList(obj).then((res) => {
          if (!res.data.code) {
            this.getList();
            return;
          }
          this.$message.error(`删除失败,${res.data.message}`);
        });
      });
    },
    addList() {
      this.form = {
        business_mold: "", //店铺状态
        content: "", //发送内容
        create_time: "", //申请日期
        memo: "", //发送备注
        mobiles: "", //发送对象,member=全部会员,staff=全部员工,英文逗号分割为手机号码组
        mold: "", // 发送模式:sms=短信,wechat=微信
        result: "", //执行发送回执结果
        schedule: "", //状态描述
        send_time: "", //执行发送完成时间
        tenant_id: null, //店铺编号
        tenant_store_name: "", //店铺名称
        type: 0, //通知类型:0=默认通知,1=营业通知
      };
      this.dialogVisible = true;
    },
    renew() {
      //更新
      this.renewStatus = true;
      setTimeout(() => {
        this.getList();
        this.renewStatus = false;
        this.$message({
          message: "刷新成功",
          type: "success",
        });
      }, 500);
    },
    save() {
      if (this.form.id) {
        setList(this.form).then((res) => {
          if (!res.data.code) {
            this.dialogVisible = false;
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }
        });
        return;
      }
      addList(this.form).then((res) => {
        if (!res.data.code) {
          this.dialogVisible = false;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        }
      });
    },
    edit(obj) {
      this.form = obj;
      this.dialogVisible = true;
    },
    dialogHide() {
      this.getList();
    },
    getList() {
      getList().then((res) => {
        this.list = res.data.data;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style  scoped>
.a {
  color: #0000cc;
}
</style>
  