import {
    request
} from '@/utils/axios.js'
export function getList(data) {
    return request('/api/peiyu/manage/notify_apply', data, "GET")
}
export function setList(data) {
    return request(`/api/peiyu/manage/notify_apply/${data.id}`, data, "PUT")
}
export function addList(data) {
    return request(`/api/peiyu/manage/notify_apply`, data, "POST")
}
export function delList(data) {
    return request(`/api/peiyu/manage/notify_apply/${data.id}`, null, "DELETE")
}
