<template>
  <div>
    <el-row>
      <el-col :span="3">
        <div style="width: 100%; height: 1px">
          <el-menu
            :default-active="index"
            class="el-menu-vertical-demo"
            background-color="#545c64"
            text-color="#fff"
            @select="select"
            active-text-color="#ffd04b"
          >
            <el-menu-item
              :index="item.index"
              v-for="(item, index) in menu"
              :key="index"
            >
              <i :class="item.icon"></i>
              <span slot="title">{{ item.pageName }}</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>
      <el-col :span="21">
        <div>
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            style="margin: 20px 0"
          >
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">{{
              menu.find((item) => item.name == activeName).pageName
            }}</el-breadcrumb-item>
          </el-breadcrumb>
          <component :is="activeName"></component>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import apply from "@/views/components/applys/apply.vue";
import defaultp from "@/views/components/defaults/defaultList.vue";
import shop from "@/views/components/shop/shopList.vue";
export default {
  components: {
    apply,
    defaultp,
    shop
  },
  data() {
    return {
      activeName: "apply",
      menu: [
        {
          name: "apply",
          pageName: "群发申请",
          index: "1",
          icon: "el-icon-s-check",
        },
        {
          name: "defaultp",
          pageName: "默认通知",
          index: "2",
          icon: "el-icon-info",
        },
        {
          name: "shop",
          pageName: "营业通知",
          index: "3",
          icon: "el-icon-s-goods",
        },
      ],
      index: "1",
    };
  },
  methods: {
    select(index) {
      this.index = index;
      this.activeName = this.menu.find((item) => item.index == index).name;
    },
  },
  mounted() {},
};
</script>
<style  scoped>
.el-menu-vertical-demo {
  position: fixed;
  width: 10vw;
  height: 100%;
}
</style>