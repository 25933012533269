import axios from "axios"
const server = 'https://business.haswallow.com/';

function request(url, data = {}, method, header = {}) {
    return new Promise((resolve, reject) => {
        if (method == 'GET') {
            axios({
                url: server + url,
                method: method,
                params: data,
            }).then(res => {
                if (res.status == 200) {
                    resolve(res)
                } else {
                    reject(res)
                }
            })
        } else {
            axios({
                url: server + url,
                method: method,
                data: data,
            }).then(res => {
                if (res.status == 200) {
                    resolve(res)
                } else {
                    reject(res)
                }
            })
        }
    }).catch((e) => {

    })
}
export {
    request
}